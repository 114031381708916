@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600&display=swap");
html {
  font-family: 'Poppins', sans-serif;
}
body {
  @apply box-border bg-gray-900 text-gray-100;
}
@tailwind base;
@tailwind components;
@tailwind utilities;

.navbarz{
  background-color: #ffffff10;
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);
}

@keyframes marquee {
  from{
    transform: translateX(100%);
  }
  to{
    transform: translateX(-150%);
  }
}